/* HomePage.module.css */
.homeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .restaurantImage {
    max-width: 50%; /* Adjust as necessary for your layout */
    margin-right: 20px;
    border-radius: 4px; /* Just to add a little style */
  }
  
  .textContent {
    max-width: 50%;
    color: #333; /* Dark text colour for readability */
    line-height: 1.6; /* Increase line height for better readability */
  }
  
  /* You might want to add media queries for responsiveness on smaller screens */
  @media (max-width: 768px) {
    .homeContainer {
      flex-direction: column;
    }
  
    .restaurantImage,
    .textContent {
      max-width: 100%;
    }
  
    .restaurantImage {
      margin-bottom: 20px;
    }
  }
  