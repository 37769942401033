/* Header.module.css */
.header {
  background-size: cover; /* Cover the entire header area */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Do not repeat the image */
  color: white; /* Adjust the text color to ensure readability */
  text-align: center; /* Center the text inside the header */
  padding: 20px 0; /* Add some padding */
  position: relative;
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 1;
}

.siteName, .nav {
  position: relative;
  z-index: 2;
}

  .nav {
    /* Add styles for your nav if needed */
  }
  
  .navList {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    padding: 10px 0; /* Add some padding */
    margin: 0;
  }
  
  .navItem {
    /* Add styles for your nav item if needed */
  }
  
  .navLink {
    color: white;
    text-decoration: none;
    font-weight: bold;
    padding: 10px;
    display: block;
    text-shadow: 2px 2px 4px #000000; /* Black shadow */
  }
  
  .activeNavLink {
    border-bottom: 2px solid white;
  }
  
  /* Media query for mobile viewports */
  @media (max-width: 768px) {
    .navList {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .siteName {
    font-size: 2.5em; /* Increase the size of the site name */
    margin-bottom: 0.5em; /* Add some space below the site name */
  }