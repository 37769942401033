.mainDishCard {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Adds a subtle shadow for depth */
  margin: 1em; /* Spacing around cards */
  padding: 1em; /* Spacing inside cards */
  border-radius: 8px; /* Rounded corners for a modern look */
  background-color: #ffffff; /* A light background to contrast the text */
  width: 250px; /* Set width */
  height: 300px; /* Set height */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevents content from spilling out */
}

.mainDishName {
  font-weight: bold;
  margin-bottom: 0.5em; /* Space between the title and description */
}

.mainDishDescription {
  color: #555; /* A darker color for readability */
  overflow-y: auto; /* Adds scroll for overflow content */
}

.mainDishSection {
  margin-bottom: 2em; /* Adds space between sections */
  border: 1px solid #ddd; /* Adds a subtle border around the section */
  padding: 1em; /* Adds padding inside the section */
  border-radius: 8px; /* Rounds the corners of the section border */
  background-color: #f8f8f8; /* A slight off-white background for the section */
}

.mainDishCategory {
  font-size: 2em; /* Increase the size of the category title */
  color: #333; /* Darker color for the text */
  padding-bottom: 0.5em; /* Space below the category title */
  border-bottom: 2px solid #ddd; /* Adds a line under the category title */
  margin-bottom: 1em; /* Space after the category title before cards start */
}

.mainDishCardsContainer {
  display: flex;
  flex-wrap: wrap; /* Allows cards to wrap to the next line */
  justify-content: center; /* Centers cards when there's extra space */
  gap: 20px; /* Space between cards */
}

.fullStar {
  color: gold; /* Color for a filled star */
}

.emptyStar {
  color: lightgray; /* Color for an unfilled star */
}

.starRating {
  display: flex;
  justify-content: center; /* Center the stars in the card */
  margin-top: auto; /* Push stars to the bottom of the card */
  font-size: 1em; /* Adjust size as needed */
}

