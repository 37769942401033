/* Updated starters.css to match mains */
.starterDishCard {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Matches shadow for depth */
    margin: 1em; /* Consistent spacing around cards */
    padding: 1em; /* Consistent spacing inside cards */
    border-radius: 8px; /* Rounded corners for a modern look */
    background-color: #ffffff; /* Light background to contrast the text */
    width: 250px; /* Set width to match mains */
    height: auto; /* Height auto to fit content */
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Keeps content contained */
  }
  
  .starterDishName {
    font-weight: bold;
    margin-bottom: 0.5em; /* Consistent space between title and description */
  }
  
  .starterDishDescription {
    color: #555; /* Darker color for readability, matches mains */
    overflow-y: auto; /* Allows scroll for overflow content */
  }
  
  .startersContainer {
    display: flex;
    flex-wrap: wrap; /* Allows cards to wrap to next line */
    justify-content: center; /* Centers cards in the container */
    gap: 20px; /* Consistent space between cards */
    padding: 20px; /* Padding around the container for better spacing */
    max-width: 800px; /* Adjust based on layout, keeps container centered */
    margin: 0 auto; /* Center the container */
  }
  
  /* If you have any specific styling for sections or headings, you can adjust them here */
  .starters-heading {
    font-size: 2em; /* Increase the size of the heading to match category titles in mains */
    color: #333; /* Darker color for the text */
    padding-bottom: 0.5em; /* Space below the heading */
    border-bottom: 2px solid #ddd; /* Adds a line under the heading */
    margin-bottom: 1em; /* Space after the heading before cards start */
  }
  
  /* You might not need these list styles anymore, but if you do for any reason, here they are */
  .starters-list,
  .special-starters-list {
    list-style-type: none; /* Removing list-style as we are now using cards */
  }
  
  .special-starters-list {
    list-style-type: none; /* Consistency with the starters-list */
  }
  
  .starters-description {
    font-style: italic;
    margin-bottom: 10px;
  }
  