.openingHours table {
    width: 100%; /* Use the full width of the openingHours container */
    border-collapse: collapse; /* Collapses the border */
  }
  
  .openingHours td {
    padding: 0.5em; /* Add some padding around the cells */
    border-bottom: 1px solid #ddd; /* A line to separate rows */
  }
  
  .mapContainer {
    margin-top: 2em; /* Add some space above the map */
  }
  
  .locationMap {
    max-width: 100%; /* Ensures the image is not bigger than its container */
    height: auto; /* Maintain the aspect ratio of the image */
    border-radius: 8px; /* Optional: rounds the corners of the image */
  }
  