.stickyFooter {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #333; /* Dark background for contrast */
    color: white;
    text-align: center;
    padding: 0.5em 0; /* A bit of padding for spacing */
  }
  
  .container {
    max-width: 1200px; /* Or whatever your site's max width is */
    margin: 0 auto;
    padding: 0 1em; /* Padding on the sides */
  }
  
  .phoneNumber {
    color: #4CAF50; /* Make the phone number stand out */
    margin-left: 0.5em;
    text-decoration: none; /* Optional: if you don’t want the underline */
  }
  
  .phoneNumber:hover,
  .phoneNumber:focus {
    text-decoration: underline; /* Optional: to indicate clickability */
  }
  